import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import sql from "../../Assets/Projects/sql.png";
import thinking from "../../Assets/Projects/thinking.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sql}
              isBlog={false}
              title="SQL Murder Mystery"
              description="There's been a Murder in SQL City! The SQL Murder Mystery is designed to be both a self-directed lesson to learn SQL concepts and commands and a fun game for experienced SQL users to solve an intriguing crime."
              ghLink="https://github.com/flakronademi"
              demoLink="https://murder.flakronademi.com/"
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={thinking}
              isBlog={true}
              title="Coming Soon"
            />
          </Col>

        
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
